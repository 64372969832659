<template>
    <div v-if="toggleValue" class="flex justify-end mt-4">
        <el-button v-if="showCommentButton && !showEditButton" @click="emitToggleComment" type="warning">{{ showComment ? "Dölj kommentar" : "Visa kommentar" }}</el-button>
        <el-button v-if="showEditButton" @click="toggleEdit" type="primary">Ändra</el-button>
        <el-button v-if="!showEditButton" @click="closeModal" type="default">Stäng</el-button>
        <el-button v-if="!showEditButton" @click="create" type="success" :loading="$waiting.is('creating')">Spara</el-button>
    </div>
</template>

<script>
export default {
    props: {
        toggleValue: {
            type: Boolean,
            default: true,
        },
        showEditButton: {
            type: Boolean,
            default: true,
        },
        toggleEdit: {
            type: Function,
        },
        closeModal: {
            type: Function,
        },
        create: {
            type: Function,
        },
        showCommentButton: {
            type: Boolean,
            default: false,
        },
        showComment: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        emitToggleComment() {
            this.$emit("toggle-comment", !this.showComment);
        },
    },
};
</script>
